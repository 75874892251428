// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.homepage section {
	padding: 5em 0;
	h1 {
		margin-bottom: 1.5em;
	}
	&.about {
		padding-bottom: 0;
		margin-bottom: 3em;
		margin-top: 3em;
	}
	&.callouts {
		padding-bottom: 2em;
		.callout-item {
			margin: 1.7em 0;
			img { width: 2em; }
			// h4 {
			// 	display: inline;
			// 	vertical-align: bottom;
			// 	margin-left: 0.2em;
			// }
		}
	}
	&.contact {
		background: $gray-300;
		box-shadow: inset 0 0 16px 0 rgba(0, 0, 0, 0.1);
		padding-bottom: 8em;
		.container {
			display: flex;
			flex-direction: column;
			align-items: center;
			.row {
				width: 100%;
			}
			#map1,
			#map2 {
				@extend .shadow;
				margin-bottom: 1em;
			}
		}
	}
}
