@mixin modal-fullscreen() {
	padding: 0 !important; // override inline padding-right added from js
	.modal-dialog {
		width: 100%;
		max-width: none;
		height: 100%;
		margin: 0;
	}
	.modal-content {
		height: 100%;
		border: 0;
		border-radius: 0;
		.modal-header {
			border: none;
		}
		.modal-body {
			overflow-y: auto;
		}
	}
}

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-down($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.modal-fullscreen#{$infix} {
			@include modal-fullscreen();
		}
	}
}

.modal-backdrop.show {
	opacity: 1.0;
	background-color: rgba($gray-light1, 0.8);
	-webkit-backdrop-filter: blur(8px) grayscale(1);
	-moz-backdrop-filter: blur(8px) grayscale(1);
	-ms-backdrop-filter: blur(8px) grayscale(1);
	-o-backdrop-filter: blur(8px) grayscale(1);
	backdrop-filter: blur(8px) grayscale(1);
}

.modal {
	.modal-dialog {
		width: 100%;
		max-width: none;
		height: 100%;
		margin: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.modal-content {
		width: calc(80% + 5.0rem);
		height: calc(80% + 5.0rem);
		border: 0;
		border-radius: 0;
		padding: 2.5rem;
		@include media-breakpoint-down(md) {
			width: calc(85% + 5.0rem);
			height: calc(85% + 5.0rem);
		}
		@include media-breakpoint-down(sm) {
			width: 90%;
			height: 95%;
		}
		@include media-breakpoint-down(xs) {
			width: 100%;
			height: 100%;
		}
		.modal-header {
			border: none;
		}
		.modal-body {
			height: 100%;
			max-height: 100vh;
			border: 0;
			overflow-x: hidden;
			overflow-y: auto;
		}
	}
}
