// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

.footer-container {
	padding: 2.5rem 1.875rem;
	color: $gray-mid2;
	font-size: 0.8rem;
	strong {
		font-weight: 400;
		color: $black;
	}
	a {
		&:hover {
			color: $black;
			text-decoration: none;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			color: $black;
			text-decoration: none;
		}
	}
	.footer-row-top {
		padding-bottom: 0.3125rem;
		// border-bottom: 1px solid $gray-white2;
		.footer-email-container {
			a {
				color: $black;
				&:hover {
					color: $black;
					text-decoration: none;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $black;
					text-decoration: none;
				}
			}
		}
		@include media-breakpoint-down(md) {
			.col-12 {
				padding: 0.25rem 2.5rem;
			}
		}
	}
	.footer-row-bottom {
		padding-top: 0.3125rem;
	}
	@include media-breakpoint-down(sm) {
		.col-12 {
			padding: 0.25rem 2.5rem;
		}
	}
}