.entry-type-team {
	.team-member {
		margin-bottom: 1em;
		img {
			// @extend .shadow;
		}
		.fa {
			line-height: 1.5;
		}
		.team-contact {
			img {
				margin-bottom: 0.625rem;
			}
		}
	}
	.team {
		.homepage & {
		}
		.row {
			&:not(:last-child) {
				// margin-bottom: 3em;
			}
			.col-12 {
				h2 { float: left; }
				// border-bottom: 1px solid lightgray;
				// margin-bottom: 1em;
			}
			.card-team {
				border-radius: 0;
				background: none;
				box-shadow: none;
				margin: 1em 0 0 0;
				padding: 1.25em;
				.card-img-top {
					border-radius: 0;
					margin-bottom: 0.5em;
					// @extend .shadow;
					-webkit-filter: grayscale(1);
					-moz-filter: grayscale(1);
					-ms-filter: grayscale(1);
					-o-filter: grayscale(1);
					filter: grayscale(1);
					-webkit-transition: filter 1.0s ease-out;
					-moz-transition: filter 1.0s ease-out;
					-ms-transition: filter 1.0s ease-out;
					-o-transition: filter 1.0s ease-out;
					transition: filter 1.0s ease-out;
					&:hover {
						-webkit-filter: grayscale(0);
						-moz-filter: grayscale(0);
						-ms-filter: grayscale(0);
						-o-filter: grayscale(0);
						filter: grayscale(0);
					}
				}
				.card-content {
					a p.card-title {
						color: $gray-mid2;
						&:hover {
							color: $black;
							text-decoration: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							color: $black;
							text-decoration: none;
						}
					}
				}
			}
		}
	}
	.investment-list {
		.investments {
			
		}
	}
	.card-investments {
		@extend .card-investments;
	}
}