// -----------------------------------------------------------------------------
// This file contains all styles related to the navigation of the site/application.
// -----------------------------------------------------------------------------

#navContainer {
	padding: 2.5rem;
}

.navbar {
	padding: 1rem;
	// box-shadow: 0px 2px 3px 0px rgba(0,0,0,.29);
	background: $white;
	.navbar-brand {
		background-image: url('/media/images/branding/avp-logo.svg');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: left center;
		@include media-breakpoint-up(md){ 
			height: 2.3em;
		}
		@include media-breakpoint-up(lg){ 
			background-position: left center; 
		}
		span { 
			opacity: 0; 
		}
	}
	.navbar-collapse {
		.navbar-nav {
			.nav-item {
				margin: 0 0.625rem !important;
				font-size: clamp(1.0rem, calc(1.0rem + 0.125 * ((100vw - 36em) / 39)), 1.125rem);
				font-size: clamp(1.0rem, -webkit-calc(1.0rem + 0.125 * ((100vw - 36em) / 39)), 1.125rem);
				font-size: clamp(1.0rem, -moz-calc(1.0rem + 0.125 * ((100vw - 36em) / 39)), 1.125rem);
				line-height: 1;
				a {
					padding: 0.0625rem 0 !important;
					border-bottom: 1px solid rgba($gray-light2,0);
					&.active {
						color: $black;
						border-bottom: 1px solid $gray-light2;
					}
				}
			}
		}
	}
	.navbar-toggler {
		margin: 0;
		padding: 0;
		width: auto;
		height: auto;
		color: $black;
		border: none;
	}
	.navbar-toggler-icon {
		margin: 0;
		padding: 0;
		width: auto;
		height: auto;
		background: none;
		color: $black;
		font-family: $font-icons2;
		display: inline-block;
		font-size: 3.0rem;
		font-weight: normal;
		font-style: normal;
		line-height: 1;
		text-rendering: auto;
		&:before {
			content: '\f014';
		}
	}
}

@include media-breakpoint-down(md) {
	#navContainer {
		padding: 1.25rem;
	}
	.navbar {
		padding: 0;
		.navbar-collapse {
			padding: 1.25rem 0;
			width: 100%;
			.navbar-nav {
				width: 100%;
				.nav-item {
					margin: 0.3125rem 0.625rem !important;
					font-size: clamp(1.5rem, calc(1.5rem + 0.125 * ((100vw - 36em) / 39)), 1.625rem);
					font-size: clamp(1.5rem, -webkit-calc(1.5rem + 0.125 * ((100vw - 36em) / 39)), 1.625rem);
					font-size: clamp(1.5rem, -moz-calc(1.5rem + 0.125 * ((100vw - 36em) / 39)), 1.625rem);
					line-height: 1;
					text-align: right;
					a {
						display: inline-block;
						padding: 0.0625rem 0 !important;
						border-bottom: 1px solid rgba($gray-light2,0);
						&.active {
							color: $black;
							border-bottom: 1px solid $gray-light2;
						}
					}
				}
			}
		}
	}
}
