// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.jumbotron {
	background: url('/media/images/background/advanced-venture-partners-header.jpg') no-repeat center/cover;
	border-radius: 0;
	height:80vh;
	width: 100%;
	display: flex;
	text-align: center;
	flex-direction: column;
	justify-content: center;
	position: fixed;
	.interior & {
		background: initial;
		position: relative;
		height: 30vh;
		justify-content: flex-end;
		color: white;
		.container-fluid {
			background: linear-gradient(to right, rgba(0,0,0,0.8), rgba(0, 0, 0, 0));
			// @extend .shadow;
		}
	}
	.container {
		.img-fluid{
			width: 30%;
		}
	}
	.interior & {
		margin-bottom: 0;
	}
	@include media-breakpoint-up(md) {
		text-align: left;
	}
}

header {
	.jumbotron{
		height: 80vh;
		border-radius: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		.jumbotron-copy {
			color: white;
			text-align: center;
		}
	}
}

.breadcrumb {
	border-radius: 0;
}

.slider {
	// margin-top: 60px;
}

.slick-slider {
	padding: 0 !important;
	background: none !important;
	
}

.banner-alert {
	padding: 0;
	border-bottom: 1px solid $gray-white2;
}
.banner-alert-content {
	color: $gray-mid2;
}
.banner-alert-content a {
	color: $black;
}
.banner-alert-content a:hover,
.banner-alert-content a:focus {
	color: $black;
}
.banner-alert p {
	margin: 0 2.5rem 0.625em 2.5rem;
}
