/**
 * Basic typography style for copy text
 */

h1,
h2,
h3,
h4 {
	font-family: $font-heading1;
}

h5,
h6,
p,
label,
button {
	font-family: $font-body1;
}

h5,
h6 {
	font-weight: 700;
}

a {
	font-family: $font-body1;
	color: $gray-600;
	&:hover,
	&:focus {
		color: $link-color;
		text-decoration: none;
	}
}

.intro-title {
	font-family: $font-heading1;
	font-size: 2.0rem;
	// text-align: center;
	font-weight: bold;
	letter-spacing: -1px;
}