.news {
  margin-top: 2.5rem;
  .card-deck {
    @include media-breakpoint-down(md){ display: block; }
  }
  .card {
    .homepage & { height: 15em; }
    color: white;
    hr {
      border-top: 1px solid white;
      width: 100%; 
    }
    background: $gray-600;
    transition: background 0.5s ease-in-out;
    &:hover,
    &:focus {
      color: white;
      background: $gray-700;
      .card-footer {
        background: $gray-700;
        color: white;
        .text-muted {
          color: white !important;
        }
      }
    }
    .card-footer {
      background: $gray-600;
      transition: background 0.5s ease-in-out;
      .text-muted { color: white !important; }
    }
  }
}
.news-item {
  margin-bottom: 3em;
  a {
    color: $gray-900;
    margin-bottom: 0.3rem; 
    &:hover,
    &:focus {
      color: $gray-600;
    }
    h4 { margin-bottom: 0.3rem; }
  }
  p {
    line-height: 1;
    font-size: 0.8rem; 
  }
}
