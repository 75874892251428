// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------
body {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	main {
		flex: 1 0 auto;
		background: white;
		z-index: 1;
	}
	&.homepage {
		main { margin-top: 80vh; }
	}
}

.card {
	border: none;
	margin: 0;
	// @extend .shadow;
}

// .homepage {
// 	main {
// 		margin: 80vh 0 0 !important;
// 	}
// }
.interior {
	main {
		margin-top: 1.25em;
	}
}
