// -----------------------------------------------------------------------------
// This file contains styles that are specific to the About page.
// -----------------------------------------------------------------------------

.entry-type-aboutUs {
	.navbar-brand {
		visibility: hidden;
		pointer-events: none;
	}
	.about-outer-container {
		.about-middle-container {
			.about-inner-container {
				.about-content-row {
					// margin-top: 2.0rem;
					min-height: calc(100vh - 16.0rem);
					display: flex;
					justify-content: center;
					align-items: center;
					.content-center-container {
						.content-center-row {
							display: flex;
							justify-content: center;
							align-items: center;
							.about-overview-container {
								padding: 0 2.5rem 2.5rem 2.5rem;
								@include media-breakpoint-down(sm) {
									position: relative;
									display: flex;
									flex-direction: column;
									justify-content: center;
									align-items: center;
									padding: 2.5rem;
									text-align: center;
								}
								.about-logo {
									padding-bottom: 1.25rem;
									max-width: 60%;
								}
								.intro-title {
									font-size: 1.5625rem;
									padding-bottom: 1.25rem;
								}
							}
							.about-detail-container {
								padding: 2.5rem;
								max-height: calc(100vh - 9.0rem);
								overflow-y: auto;
								@include media-breakpoint-up(sm) {
									/* Hide scrollbar for Chrome, Safari and Opera */
									&::-webkit-scrollbar {
									  display: none;
									}

									/* Hide scrollbar for IE, Edge and Firefox */
									-ms-overflow-style: none;  /* IE and Edge */
									scrollbar-width: none;  /* Firefox */
								}
								@include media-breakpoint-down(sm) {
									overflow-y: visible;
									max-height: fit-content;
								}
								.about-text-container {
									color: $gray-dark2;
									p.opening {
										color: $black;
										font-size: 1.125rem;
									}
									ul.callout-list {
										margin: 2.5rem 0;
										@supports (-webkit-marquee-repetition:infinite) and (object-fit:fill) {
											list-style: none; /* Remove default bullets */
										}
										li {
											margin: 1.25rem 0 1.25rem -1.875rem;
											padding-left: 0.625em;
											&::marker {
												content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
												color: $black;
												font-weight: bold;
												font-size: 1.25em;
												line-height: 0;
											}
										}
									}
									a {
										color: $black;
										text-decoration: underline;
										&:hover,
										&:focus {
											color: $gray-dark2;
											text-decoration: underline;
										}
									}
								}
								.about-detail-list-container {
									ul.detail-list-all {
										margin: 0 0 2.5rem 0;
										padding: 0;
										list-style: none; /* Remove default bullets */
										display: flex;
										justify-content: space-between;
										align-items: center;
										flex-wrap: wrap;
										gap: 0.625rem;
										@include media-breakpoint-down(lg) {
											display: block;
											justify-content: center;
										}
										// @include media-breakpoint-down(sm) {
										// 	display: flex;
										// 	justify-content: space-between;
										// }
										li {
											margin: 0 0 0 0;
											// padding: 0.8125rem;
											display: inline-block;
											@include media-breakpoint-down(lg) {
												display: block;
												padding: 0.8125rem 0;
												text-align: center;
											}
											// @include media-breakpoint-down(sm) {
											// 	display: inline-block;
											// }
											a {
												// display: block;
												color: $gray-dark2;
												font-weight: 700;
												position: relative;
												text-decoration: none;
												// padding: 0.125rem 0;
												border-bottom: 1px solid rgba($gray-light2,1);
												&:hover {
													color: $black;
													text-decoration: none;
													border-bottom: 1px solid $gray-light2;
												}
												&:active, &:focus, &:not(:disabled):not(.disabled):focus {
													color: $gray-dark2;
													text-decoration: none;
													border-bottom: 1px solid $gray-light2;
												}
												// &::after {
												// 	content: "\f00b";
												// 	font-family: $font-icons2;
												// 	display: inline-block;
												// 	position: absolute;
												// 	top: 0.3125em;
												// 	right: -1.25rem;
												// 	color: $black;
												// 	font-size: 0.625rem;
												// 	font-weight: normal;
												// 	font-style: normal;
												// 	font-variant: normal;
												// 	text-rendering: auto;
												// 	-webkit-font-smoothing: antialiased;
												// 	text-rendering: auto;
												// }
											}
											// &:not(:last-child) {
											// 	border-bottom: 1px solid $gray-white2;
											// }
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.detail-list-body-container {
		padding-bottom: 2.5rem;
	}
}