.filter-buttons {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.btn-group-investment-category {
		
	}
	.btn-group-investment-status {

	}
	@include media-breakpoint-down(xs) {
		display: block;
		.btn-group-investment-category {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			padding: 0.625rem 0;
			text-align: center;
			border-top: 1px solid $gray-white2;
			border-bottom: 1px solid $gray-white2;
			.btn {
				flex: 0 0 auto;
			}
		}
		.btn-group-investment-status {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			padding: 0.625rem 0;
			border-bottom: 1px solid $gray-white2;
			.btn {
				flex: 0 0 auto;
			}
		}
	}
}

.investment-detail {
	.col-12 .img-fluid { 
		width: 100%; 
	}
	.col-md-4, .col-md-3 {
		line-height: 3;
		.investment-image {
			max-width: 80%;
			&.investment-rent-the-runway { 
				max-width: 40%;
			}
			@include media-breakpoint-down(md) {
				display: block;
				margin-left: auto;
				margin-right: auto;
				max-width: 90%;
			}    
		}
		.investment-external-link {
			color: $gray-mid2;
			&:hover {
				color: $black;
				text-decoration: none;
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				color: $black;
				text-decoration: none;
			}
			.fa  {
				padding-left: 0.3125rem;
				transform: translateY(-0.0625rem);
				font-size: 0.625rem;
			}
		}
		ul.investment-team-names {
			li {
				line-height: 1.25;
				a.investment-team-name {
					color: $gray-mid2;
					line-height: 1.5;
					&:hover {
						color: $black;
						text-decoration: none;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $black;
						text-decoration: none;
					}
				}
			}
		}
		hr {
			visibility: hidden;
			margin-right: 3em; 
		}
	}
	.col-md-8 { 
		margin-top: 1em;
		@include media-breakpoint-up(md) { 
			margin-top: initial; 
		}
		.investment-news {
			margin-top: 3em;
			.news-item{
				margin-bottom: 1rem;
				h4 { 
					font-size: 1rem; 
				}
				p { 
					font-size: 0.8rem; 
				}
			}
		}
	}
	.col-md-4 .img-fluid {
		max-width: 80%;	
	}
}

.investments {
	.collapse { 
		margin-bottom: 3em; 
	}
}

.card-investments {
	margin: 0;
	padding: 0;
	height: 15rem;
	border-radius: 0;
	box-shadow: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	&:hover {
		.company-img {
			opacity: 1;
			-webkit-filter: grayscale(0);
			-moz-filter: grayscale(0);
			-ms-filter: grayscale(0);
			-o-filter: grayscale(0);
			filter: grayscale(0);
		}
	}
	.company-img {
		width: 75%;
		height: 75%;
		background-repeat: no-repeat; 
		background-size: contain; 
		background-position: center;
		opacity: 0.66;
		-webkit-filter: grayscale(1);
		-moz-filter: grayscale(1);
		-ms-filter: grayscale(1);
		-o-filter: grayscale(1);
		filter: grayscale(1);
		-webkit-transition: opacity 1.0s ease-out, filter 1.0s ease-out;
		-moz-transition: opacity 1.0s ease-out, filter 1.0s ease-out;
		-ms-transition: opacity 1.0s ease-out, filter 1.0s ease-out;
		-o-transition: opacity 1.0s ease-out, filter 1.0s ease-out;
		transition: opacity 1.0s ease-out, filter 1.0s ease-out;
	}
	img {
		max-width: 90%; 
		height: auto;
		box-shadow: none;
	}
	&.card-curology {
		img { width: 90%; }
	}
	&.card-rent-the-runway {
		img { width: 40%; }
	}    
	.card-img-overlay {
		// border: 1px solid rgba($gray-300, 0);
		transition: border 1.0s ease-in;
		&:hover,
		&:focus {
			// border: 1px solid $gray-500;
		}
	}
}