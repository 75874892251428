// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn {
	border-radius: 0;
	text-decoration: none !important;
	-webkit-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	-moz-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	-ms-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	-o-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	&:hover:not(:disabled):not(.disabled) {
		text-decoration: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		text-decoration: none;
	}
	.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
		outline: 0 !important;
		outline-offset: 0  !important;
		background-image: none !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
}
.btn-primary {
	@extend .shadow;
	border-color: $gray-800;
	background-color: $gray-800;
	margin-top: 3em;

	&:hover,
	&:focus {
		border-color: $link-color;
		background-color: $link-color;
	}
}

.btn-outline-primary {
	border-color: $gray-600;
	color: $gray-600;

	&:hover,
	&:focus {
		background-color: $link-color;
		border-color: $link-color;
		color: white;
	}
}

.btn-group-vertical {
	.btn { margin: 0.5em 0; }
}

.btn-link {
	color: rgba(0,0,0,0.5);
	&:hover, &:focus {
		color: black;
		text-decoration: none;
	}
	&.active {
		color: black;
	}
}
